import { googleLogout, useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

import googleLogo from './assets/google-logo.png'
import logo from './assets/logo.png'
import whiteLogo from './assets/white-logo.png'
import CreateNftForm from './components/CreateNftForm'

type Profile = {
	picture: string
	name: string
	email: string
}

const App = () => {
	const [accesToken, setAccesToken] = useState('')
	const [profile, setProfile] = useState<Profile | null>(null)

	const login = useGoogleLogin({
		onSuccess: (response) => setAccesToken(response.access_token),
		onError: (error) => console.log('Login Failed:', error),
	})

	useEffect(() => {
		if (accesToken !== '') {
			axios
				.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accesToken}`, {
					headers: {
						Authorization: `Bearer ${accesToken}`,
						Accept: 'application/json',
					},
				})
				.then((res) => {
					setProfile(res.data)
				})
				.catch((err) => console.log(err))
		}
	}, [accesToken])

	const logOut = () => {
		googleLogout()
		setProfile(null)
	}

	return (
		<div className="flex h-screen">
			<img className="absolute mt- ml-0 w-40" src={whiteLogo} alt="logo" />
			<div className="flex w-screen justify-center items-center py-6 bg-violet-900 overflow-auto">
				{profile ? (
					<div className="flex w-3/4 flex-col items-center max-h-screen">
						<CreateNftForm owner={profile.email} />
						<button onClick={logOut} type="button" className="flex w-1/2 justify-center font-bold text-violet-900 py-2 px-4 mt-3 rounded-lg bg-white">
							Logout
						</button>
					</div>
				) : (
					<div className="flex h-1/2 justify-around flex-col bg-white rounded-lg px-20 pt-1 py-10">
						<div className="flex flex-col items-center ">
							<img className="w-80 self-center" src={logo} alt="logo" />
							<p className="font-semibold text-lg text-violet-900">Login. Create NFT. Distribute.</p>
						</div>
						<button onClick={() => login()} type="button" className="flex flex-row items-center justify-center text-violet-900 py-2 px-4 mt-3 rounded-lg border border-violet-900">
							<img className="w-5 h-5" src={googleLogo} alt="google" />
							<p className="pl-2 py-1">Log in with Google</p>
						</button>
					</div>
				)}
			</div>
		</div>
	)
}
export default App
