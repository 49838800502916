import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export interface ApiResponse {
  message: string;
}

axios.defaults.baseURL = API_URL

export async function sendEmailAndCreateToken(emails: string[], owner: string, uri: string): Promise<ApiResponse> {
	try {
		const response = await axios.post('/send-emails-and-create-token', {
			emails,
			owner,
			uri,
		})
		return response.data
	} catch (error) {
		console.error('Error sending email and creating token:', error)
		throw error
	}
}
