import Papa from 'papaparse'
import React, { useRef, useState } from 'react'

interface Props {
	emails: string[];
	setEmails: React.Dispatch<React.SetStateAction<string[]>>;
}

const CsvEmailParser = ({ emails, setEmails }: Props) => {
	const fileInputRef = useRef<HTMLInputElement>(null)
	const [selectedFile, setSelectedFile] = useState<File | undefined>()

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0]
		setSelectedFile(file)

		if (file) {
			Papa.parse(file, {
				complete: (result) => {
					const data = result.data as string[]

					const parsedEmails = data
						.flat()
						.map((item) => item.trim())
						.filter((item) => item !== '')
						.map((email) => ({ email }))

					setEmails(parsedEmails.map((email) => email.email))
				},
				error: () => {
					alert('There was an error parsing the CSV file.')
				},
			})
		}
	}

	const handleDelete = () => {
		setEmails([])
		setSelectedFile(undefined)
		// Clear the value of the file input
		if (fileInputRef.current) {
			fileInputRef.current.value = ''
		}
	}

	return (
		<div>
			<button
				className="font-semibold bg-violet-800 text-white rounded p-1 px-3 rounded-lg"
				type="button"
				onClick={() => fileInputRef.current?.click()}
			>
				Choose CSV File
			</button>
			{emails.length > 0 && (
				<button
					className=" text-violet-800 rounded p-1 px-3 rounded-lg"
					type="button"
					onClick={handleDelete}
				>
					Delete
				</button>
			)}
			{selectedFile && (
				<p>
					Selected file:
					{' '}
					{selectedFile?.name}
				</p>
			)}
			<input
				type="file"
				ref={fileInputRef}
				onChange={handleFileChange}
				style={{ display: 'none' }}
			/>
			<ul>
				{emails?.map((item) => (
					<li key={item}>{item}</li>
				))}
			</ul>
		</div>
	)
}

export default CsvEmailParser
