/* eslint-disable import/no-extraneous-dependencies */
import './index.css'
import '@rainbow-me/rainbowkit/styles.css'

import {
	getDefaultWallets,
	RainbowKitProvider,
} from '@rainbow-me/rainbowkit'
import { GoogleOAuthProvider } from '@react-oauth/google'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {
	configureChains, createClient, WagmiConfig,
} from 'wagmi'
import { goerli } from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

import App from './App'
import reportWebVitals from './reportWebVitals'

const { chains, provider } = configureChains(
	[goerli],
	[
		alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY || '' }),
		publicProvider(),
	],
)

const { connectors } = getDefaultWallets({
	appName: 'NFT Airdrop',
	chains,
})

const wagmiClient = createClient({
	autoConnect: true,
	connectors,
	provider,
})

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
)
root.render(
	<GoogleOAuthProvider clientId="85215103351-linmbbouk7a3ejep7tshafo3m1u1uokt.apps.googleusercontent.com">
		<WagmiConfig client={wagmiClient}>
			<RainbowKitProvider chains={chains}>
				<React.StrictMode>
					<App />
				</React.StrictMode>
			</RainbowKitProvider>
		</WagmiConfig>
	</GoogleOAuthProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
