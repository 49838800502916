import React from 'react'

import loading from '../../assets/loading.png'

const Spinner = () => (
	<div className="flex justify-center">
		<img className="animate-spin w-6" src={loading} alt="logo" />
	</div>
)

export default Spinner
